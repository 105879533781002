import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, concatMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DirectoryConfig, DirectoryFieldForFilter } from '../../models/directory-config.model';
import { DirectoryAddRequestService } from '../../services/directory-add-request/directory-add-request.service';
import { DirectorySearchService } from '../../services/directory-search/directory-search.service';
import { DirectorySharedDataService } from '../../services/directory-shared-data.service';
import { DirectoryAddRequestValidationDialogComponent } from '../../components/dialog/directory-add-request-validation-dialog/directory-add-request-validation-dialog.component';
import { DirectoryAskLeaveDialogComponent } from '../../components/dialog/directory-ask-leave-dialog/directory-ask-leave-dialog.component';
import { Requester } from '../../models/directory-modifications-request.model';
import { Location } from '@angular/common';
import { DirectoryUtilsService } from '../../services/directory-utils/directory-utils.service';
import { DirectoryEmbedRequestService } from '../../services/directory-embed-request/directory-embed-request.service';

@Component({
  templateUrl: './directory-add-request.component.html',
  styleUrls: ['./directory-add-request.component.scss']
})
export class DirectoryAddRequestComponent implements OnInit {
  public widgetConfig: DirectoryConfig;
  public tmpWidgetConfig: DirectoryConfig;
  public currentLang: string = 'fr';
  public availableFields: any[];

  constructor(
    private route: ActivatedRoute,
    public directoryUtils: DirectoryUtilsService,
    private directoryData: DirectorySharedDataService,
    private directorySearch: DirectorySearchService,
    public directoryAddRequestService: DirectoryAddRequestService,
    private directoryEmbedRequestService: DirectoryEmbedRequestService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.translate.use(this.route.snapshot.paramMap.get('lang'));
    this.currentLang = this.route.snapshot.paramMap.get('lang');


    if (this.directoryData.isInitialized()) {
      this.directoryData.getDirectoryConfig().subscribe((config: DirectoryConfig) => {
        // some protection
        if(!(config?.addRequests?.hasAddRequestsPerEmbeddedForm)) {
          window.location.replace('https://www.platform-craft.eu/')
        }

        this.directoryAddRequestService.initForm(config);
        this.widgetConfig = config;

      })
    } else {
      this.directoryData
      .findDirectoryConfig(this.route.snapshot.paramMap.get('widgetId'))
      .pipe(
        concatMap((config: DirectoryConfig) => {
            if(!(config?.addRequests?.hasAddRequestsPerEmbeddedForm)) {
              window.location.replace('https://www.platform-craft.eu/')
            }
  
            this.directoryUtils.isHostAuthorized(config.authorizedHosts);
            this.directoryData.setHeaders(config.apiKey);
            this.directorySearch.setHeaders(config.apiKey);
            this.directorySearch.setConcernedSubject(config.isFocusOnAssociatedDB, config.concernedAssociatedDB);
            this.directoryUtils.setSheet(config.addedStyleLink);
            //temporary add future way to deal with theses fields
            if(config?.sheet?.description?.id) {
              config.sheet.description['overrideTitle'] = {'fr':"Description",'en':"Description"};
              config.sheet.description['showfieldTitle'] = true;
            }
  
            this.tmpWidgetConfig = config;
  
            this.directoryData.setAvailableFields();
  
            return this.directoryData.getAvailableFields().pipe(filter(fields=> fields != null))}))
          .subscribe((fields) => {
            this.availableFields = fields;
            // Take fields from add subrequests if it's been requested by add button in add-request-form component
            this.addPropertiesToFields(this.tmpWidgetConfig);
  
            this.directoryData.changeWidgetConfig(this.tmpWidgetConfig);
  
            this.directoryData.widgetLang = this.route.snapshot.paramMap.get('lang');
            this.directorySearch.widgetLang = this.route.snapshot.paramMap.get('lang');
            this.directoryAddRequestService.initForm(this.tmpWidgetConfig);
            this.widgetConfig = this.tmpWidgetConfig;
          });
    }
  }

  public addPropertiesToFields(config) {
    config.sheet.fieldsgroup.forEach((group) =>
      group.fields.forEach((field) => {
        const dynamicField = this.availableFields.find((x) =>
          x.field_id === field.id
        );
        field.domain = dynamicField?.associated_data_domain_id;
        field.readOnly = dynamicField?.readonly;
        field.isExternal = dynamicField?.isExternal;
    }));
    config?.addRequests?.fieldsgroup.forEach((group) =>
      group.fields.forEach((field) => {
        const dynamicField = this.availableFields.find((x) =>
          x.field_id === field.id
        );
        field.associated_data_id = dynamicField?.associated_data_domain_id;
        field.readOnly = dynamicField?.readonly;
        field.isExternal = dynamicField?.isExternal;
        field.communityRestriction = dynamicField?.accessible_communities;
        field.interestAreaRestriction = dynamicField?.accessible_interest_areas;
        field.ancestry = dynamicField?.ancestry;
    }));
    config?.filters?.list.forEach((filter: DirectoryFieldForFilter) => {
      if (filter.type == 'LinkedChoice') {
        // update filter properties to be more dynamic
        const dynamicField = this.availableFields.find((x) =>
          x.field_id === filter.id
        );
        filter.range = dynamicField?.associated_data_id
        filter.associated_data_id = dynamicField?.associated_data_id
        filter.ancestry = dynamicField?.ancestry
    }});
  }

  openValidationDialog() {
    // Prepare embed service object with new entities/elements to send
    this.directoryEmbedRequestService.prepareNewObjectsForValidationDialog();

    const dialogRef = this.dialog.open(DirectoryAddRequestValidationDialogComponent, {
      width: '600px',
      maxWidth: '100vw',
      maxHeight: '90vh',
      data: {
        community: this.directoryAddRequestService.communityForm.get('community').value,
        interest_areas: this.widgetConfig.interestAreas
      },
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((requester: Requester) => {});
  }

  public askGoBack() {
    if (this.directoryAddRequestService.addRequestForm === undefined ||
        this.directoryAddRequestService.addRequestForm.untouched) {
      this.goBack();
    } else {
      const dialogConfirm = this.dialog.open(DirectoryAskLeaveDialogComponent);
      dialogConfirm.afterClosed().subscribe( leave => {
        if (leave) {
          this.goBack();
        }
      });
    }
  }

  public goBack() {
    this.location.back();
  }
}
