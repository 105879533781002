import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { concatMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DirectoryResultsStoreService } from './../../services/directory-results-store/directory-results-store.service';
import { DirectorySearchService } from './../../services/directory-search/directory-search.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryUtilsService } from '../../services/directory-utils/directory-utils.service';

import { OpenedElement } from './../../models/directory-opened-element.model';
import { DirectoryConfig, DirectoryFieldForFilter } from 'src/app/directory/models/directory-config.model';
import { DirectoryEntitySummary } from 'src/app/directory/models/directory-entity-summary.model';
import { DirectoryEntityDialogComponent } from '../dialog/directory-entity-dialog/directory-entity-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DirectoryMapComponent } from '../directory-map/directory-map.component';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss'],
})
export class DirectoryComponent implements OnInit, OnDestroy {
  public widgetConfig: DirectoryConfig;
  public hasListDisplayed: boolean;
  public hasMapDisplayed: boolean;
  public hasLegendDisplayed: boolean;
  public tmpWidgetConfig: DirectoryConfig;
  public availableFields: any[];

  public currentOpenedEntityId: DirectoryEntitySummary['entity_id'];
  public openedLinkedElementId: string;
  public goBack: boolean = false;

  public isEntitySheetOpened: boolean = false;
  public isLinkedElementSheetOpened: boolean = false;
  public subscriptionLevels: Subscription;
  public subscriptionFields: Subscription;

  public tmpOpenedElement: OpenedElement;

  public criteriaFilters: string = null;

  @Output() closeDialog = new EventEmitter<boolean>();

  @ViewChild(DirectoryMapComponent) directoryMap: DirectoryMapComponent;

  constructor(
    private route: ActivatedRoute,
    public directoryUtils: DirectoryUtilsService,
    private resultsStore: DirectoryResultsStoreService,
    private directoryData: DirectorySharedDataService,
    private directorySearch: DirectorySearchService,
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.directoryData.widgetId = this.route.snapshot.paramMap.get('widgetId');

    this.translate.use(this.route.snapshot.paramMap.get('lang'));
    this.directoryData.widgetLang = this.route.snapshot.paramMap.get('lang');
    this.directorySearch.widgetLang = this.route.snapshot.paramMap.get('lang');

    this.subscriptionFields = this.directoryData.findDirectoryConfig(this.route.snapshot.paramMap.get('widgetId')).pipe(
      concatMap((config: DirectoryConfig) => {
        // Check if there are any filters criteria from the URL
        if (this.route.snapshot.queryParamMap.has('criteria')) {
          // If the header bar is not being displayed, filter results from start
          if (!config.layout.hasFilters) {
            this.directorySearch.setParamCriteriaString = this.route.snapshot.queryParamMap.get('criteria');
          } else {
            // We need to pass the criteria's values to initialise our filters
            this.criteriaFilters = this.route.snapshot.queryParamMap.get('criteria');
          }
        }

        this.directoryUtils.isHostAuthorized(config.authorizedHosts)

        if(config.addRequests && config.addRequests.forceAddRequests && !this.route.snapshot.url.map(x => x.path).includes("demande-ajout")) {
          this.router.navigateByUrl(
            `/${this.route.snapshot.paramMap.get('lang')}/annuaire/${this.route.snapshot.paramMap.get('widgetId')}/demande-ajout`
            );
        }

        //temporary add future way to deal with these fields
        if(config?.sheet?.description?.id) {
          config.sheet.description['overrideTitle'] = {'fr':"Description",'en':"Description"};
          config.sheet.description['showfieldTitle'] = true;
        }
        this.directoryData.setHeaders(config.apiKey);
        this.directorySearch.setHeaders(config.apiKey);
        this.directorySearch.setConcernedSubject(config.isFocusOnAssociatedDB, config.concernedAssociatedDB);
        this.directoryUtils.setSheet(config.addedStyleLink);
        // console.log('initDirectoryComp')
        if(!this.directoryData.isInitialized()) this.directoryData.setAvailableFields();
        this.tmpWidgetConfig = config;
        this.directoryData.changeListState(
          this.isDisplayed(
            config.layout?.core?.hasList,
            config.layout?.init?.hasListDisplayedOnInit
          )
        );
        this.directoryData.changeMapState(
          this.isDisplayed(
            config.layout?.core?.hasMap,
            config.layout?.init?.hasMapDisplayedOnInit
          )
        );
        return this.directoryData.getAvailableFields();
      })).subscribe((fields) => {
        // console.log("FieldsDirectoryComp",fields)
        if (fields && !this.directoryData.isInitialized()) {
          // console.log("Reset fields and config FieldsDirectoryComp")
          this.availableFields = fields;
          this.addPropertiesToFields();
          this.widgetConfig = this.tmpWidgetConfig;
          // console.log("widgetConfigDirectoryComp-Change",this.widgetConfig)
          this.directoryData.changeWidgetConfig(this.widgetConfig);
          this.directorySearch.findResults();
        } else if (fields && this.directoryData.isInitialized()) {
          this.availableFields = fields;
          this.addPropertiesToFields();
          this.widgetConfig = this.tmpWidgetConfig;
          if (this.tmpOpenedElement) {
            this.resultsStore.setOpenedElement(this.tmpOpenedElement);
          }
        }

      });

    this.directoryData.getHasListDisplayed()
      .subscribe((state) => {
        this.hasListDisplayed = state;
      });
    this.directoryData.getHasMapDisplayed()
      .subscribe((state) => {
        this.hasMapDisplayed = state;
      });
    this.directoryData.getHasLegendDisplayed()
      .subscribe((state) => {
      this.hasLegendDisplayed = state;
    });

    // Work with OpenedElement details components only if fullSize layout has not been activated,
    // otherwise we'll open a MatDialog
    if ((!this.subscriptionLevels || this.subscriptionLevels.closed) && !(this.widgetConfig?.layout.isFullSize)) {
      this.subscriptionLevels = this.resultsStore.getOpenedElement().subscribe((openedElement: OpenedElement) => {
        if (openedElement) { //Executes once the element is selected
          if (!this.widgetConfig) {
            this.tmpOpenedElement = openedElement;
          } else {
            if (openedElement && openedElement.id) {
              if (openedElement.isMain) this.resultsStore.setParentOpenedElement(openedElement);

              if (openedElement.isMain || this.goBack) { //Opens main Element
                this.currentOpenedEntityId = openedElement.id;
                this.openedLinkedElementId = null;
                this.closeMainElement();
                this.openMainElement(openedElement.id);
                this.closeLinkedElement();
                this.goBack = false;
              } else { //Opens linked Element
                this.currentOpenedEntityId = null;
                this.openedLinkedElementId = openedElement.id;
                this.openLinkedElement(openedElement.id);
                this.closeMainElement();
              }
            }
          }
        } else { //Starts with everything closed + close button clicked
          this.currentOpenedEntityId = null;
          this.openedLinkedElementId = null;
          this.goBack = false;
          this.closeMainElement();
          this.closeLinkedElement();
        }
      });
    }
  }

  public addPropertiesToFields(){
    this.tmpWidgetConfig.sheet.fieldsgroup.forEach((group) =>
      group.fields.forEach((field) => {
        const dynamicField = this.availableFields.find((x) =>
          x.field_id === field.id
        );
        field.domain = dynamicField?.associated_data_domain_id;
        field.readOnly = dynamicField?.readonly;
        field.isExternal = dynamicField?.isExternal;
    }));
    this.tmpWidgetConfig?.addRequests?.fieldsgroup.forEach((group) =>
      group.fields.forEach((field) => {
        const dynamicField = this.availableFields.find((x) =>
          x.field_id === field.id
        );
        field.associated_data_id = dynamicField?.associated_data_domain_id;
        field.readOnly = dynamicField?.readonly;
        field.isExternal = dynamicField?.isExternal;
        field.communityRestriction = dynamicField?.accessible_communities;
        field.interestAreaRestriction = dynamicField?.accessible_interest_areas;
        field.ancestry = dynamicField?.ancestry;
    }));
    this.tmpWidgetConfig?.filters?.list.forEach((filter: DirectoryFieldForFilter) => {
      if (filter.type == 'LinkedChoice') {
        // update filter properties to be more dynamic
        const dynamicField = this.availableFields.find((x) =>
          x.field_id === filter.id
        );
        filter.range = dynamicField?.associated_data_id
        filter.associated_data_id = dynamicField?.associated_data_id
        filter.ancestry = dynamicField?.ancestry
    }});    
  }

  ngOnDestroy(): void {
    this.subscriptionFields.unsubscribe();
    this.subscriptionLevels.unsubscribe();
  }

  public openEntityInDialog() {
    if (this.resultsStore.getOpenedElementSync()) { // si l'élément n'est pas un cluster
      if (this.widgetConfig.layout.isFullSize) {
        this.dialog.open(DirectoryEntityDialogComponent, {
          width: '1280px',
          maxWidth: '100vw',
          maxHeight: '90vh',
          panelClass: 'entity-sheet-dialog',
          closeOnNavigation: false
        });
      };
    } 
  };


  public isLayoutMixed(): boolean {
    if (this.hasMapDisplayed && this.widgetConfig.layout.core.hasList) {
      return true;
    }
    return false;
  }
  public isLayoutListOnly(): boolean {
    if (!this.hasMapDisplayed && this.widgetConfig.layout.core.hasList) {
      return true;
    }
    return false;
  }
  private isDisplayed(exist: boolean, displayedOnInit: boolean): boolean {
    if (exist && displayedOnInit) {
      return true;
    }
    return false;
  }
  public isLayoutMapOnly(): boolean {
    if (this.widgetConfig.layout.core.hasMap && !this.widgetConfig.layout.core.hasList) {
      return true;
    }
    return false;
  }
  public isListOnTheRight(): string {
    if (this.widgetConfig && this.widgetConfig?.layout.isListOnLeft) {
      return 'start';
    }
    return 'end';
  }
  public isLegendOnTheRight(): string {
    if (this.widgetConfig && this.widgetConfig?.layout.isLegendOnLeft) {
      return 'start';
    }
    return 'end';
  }
  public isSheetOnTheRight(): string {
    if (this.widgetConfig) {
      if (
        (!this.widgetConfig.layout.core.hasList && this.widgetConfig.layout.isLegendOnLeft)
        || (this.widgetConfig.layout.core.hasList && this.widgetConfig.layout.isListOnLeft)
      ) {
        return 'start';
      }
    }
    return 'end';
  }

  public openMainElement(id: DirectoryEntitySummary['entity_id']): void {
    this.isEntitySheetOpened = true;
  }
  public closeMainElement(): void {
    this.isEntitySheetOpened = false;
  }

  public openLinkedElement(id: string): void {
    this.isLinkedElementSheetOpened = true;
  }
  public closeLinkedElement(): void {
    this.isLinkedElementSheetOpened = false;
  }

  stateOfSecondarySheet(state: boolean) {
    this.goBack = state;

    let parentOpenedElement: OpenedElement = this.resultsStore.getParentOpenedElement();
    this.resultsStore.setParentOpenedElement(null);
    this.resultsStore.setOpenedElement(parentOpenedElement);
  }

  receiveUpdateMapSignal(event:boolean) {
    this.directoryMap.resizeMap();
  }

}
