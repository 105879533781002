import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { concatMap } from 'rxjs/operators';

import { DirectoryUtilsService } from '../../services/directory-utils/directory-utils.service';
import { DirectoryModificationsRequestService } from '../../services/directory-modifications-request/directory-modifications-request.service';
import { DirectorySearchService } from './../../services/directory-search/directory-search.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';

import { ModificationsValue, Requester } from './../../models/directory-modifications-request.model';
import { DirectoryConfig, DirectoryFieldForFilter } from '../../models/directory-config.model';
import { DirectoryModificationsRequestValidationComponent } from '../../components/dialog/directory-modifications-request-validation/directory-modifications-request-validation.component';
import { DirectoryAskLeaveDialogComponent } from '../../components/dialog/directory-ask-leave-dialog/directory-ask-leave-dialog.component';
import { Subscription } from 'rxjs';
import { DirectoryEmbedRequestService } from '../../services/directory-embed-request/directory-embed-request.service';

@Component({
  selector: 'app-directory-entity-page',
  templateUrl: './directory-entity-page.component.html',
  styleUrls: ['./directory-entity-page.component.scss'],
})
export class DirectoryEntityPageComponent implements OnInit, OnDestroy {
  public entityId: string;
  public widgetConfig: DirectoryConfig;
  public tmpWidgetConfig: DirectoryConfig;
  public modificationsRequests: ModificationsValue[] = [];
  public openedElementRange: string;
  public availableFields: any[];
  public subscriptionFields: Subscription;

  constructor (
    private location: Location,
    private route: ActivatedRoute,
    public directoryUtils: DirectoryUtilsService,
    private directoryData: DirectorySharedDataService,
    private directorySearch: DirectorySearchService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    public directoryModificationsRequest: DirectoryModificationsRequestService,
    private directoryEmbedRequestService: DirectoryEmbedRequestService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('editMode') === 'modifications') {
      this.directoryModificationsRequest.isEditionModeActive = true;
    }
    this.directoryData.widgetId = this.route.snapshot.paramMap.get('widgetId');

    this.translate.use(this.route.snapshot.paramMap.get('lang'));
    this.directoryData.widgetLang = this.route.snapshot.paramMap.get('lang');
    this.directorySearch.widgetLang = this.route.snapshot.paramMap.get('lang');

    this.subscriptionFields = this.directoryData.findDirectoryConfig(this.route.snapshot.paramMap.get('widgetId')).pipe(
      concatMap((config: DirectoryConfig) => {
        this.directoryUtils.isHostAuthorized(config.authorizedHosts);
        this.directoryData.setHeaders(config.apiKey);
        this.directorySearch.setHeaders(config.apiKey);
        this.directorySearch.setConcernedSubject(config.isFocusOnAssociatedDB, config.concernedAssociatedDB);
        this.directoryUtils.setSheet(config.addedStyleLink);
        //temporary add future way to deal with theses fields
        if(config?.sheet?.description?.id) {
          config.sheet.description['overrideTitle'] = {'fr':"Description",'en':"Description"};
          config.sheet.description['showfieldTitle'] = true;
        }
        if(config?.sheet?.banner?.id) {
          config.sheet.banner['overrideTitle'] = {'fr':"Bannière",'en':"Banner"};
          config.sheet.banner['showfieldTitle'] = true;
        }
        if(config?.sheet?.logo?.id) {
          config.sheet.logo['overrideTitle'] = {'fr':"Logo",'en':"Logo"};
          config.sheet.logo['showfieldTitle'] = true;
        }
        //console.log('initEntityPage')
        if(!this.directoryData.isInitialized()) this.directoryData.setAvailableFields();
        this.tmpWidgetConfig = config;
        // this.directoryData.changeWidgetConfig(config);
        this.directoryData.concernedAssociatedDB = config.concernedAssociatedDB;

        if(this.route.snapshot.paramMap.get('domain')) { // Second level (element domain). If it's empty, it's an Entity
          this.openedElementRange = this.route.snapshot.paramMap.get('domain');
        } else {
          this.openedElementRange = config.concernedAssociatedDB; // First level (element domain). It comes from the widget configuration.
        }

        this.entityId = this.route.snapshot.paramMap.get('entityId');
        this.directoryModificationsRequest.currentElementDomain = this.openedElementRange;


        if(config.addRequests && config.addRequests.forceAddRequests && !this.route.snapshot.url.map(x => x.path).includes("demande-ajout")) {
          this.router.navigateByUrl(
            `/${this.route.snapshot.paramMap.get('lang')}/annuaire/${this.route.snapshot.paramMap.get('widgetId')}/demande-ajout`
            );
        }

        return this.directoryData.getAvailableFields();
      })
    ).subscribe((fields) => {
      //console.log("FieldsEntityPage",fields)
      if(fields && !this.directoryData.isInitialized()) {
        this.availableFields = fields;
        this.addPropertiesToFields();
        this.widgetConfig = this.tmpWidgetConfig;
        //console.log("widgetConfigEntityPage-Change",this.widgetConfig)
        this.directoryData.changeWidgetConfig(this.widgetConfig);
      } else if (fields && this.directoryData.isInitialized()){
        this.availableFields = fields;
        this.addPropertiesToFields();
        this.widgetConfig = this.tmpWidgetConfig;
      }
    });

    this.directoryModificationsRequest.getModificationsRequests().
      subscribe((modificationsRequests: ModificationsValue[]) => {
        this.modificationsRequests = modificationsRequests;
    });
  }

  public addPropertiesToFields() {
    this.tmpWidgetConfig.sheet.fieldsgroup.forEach((group) =>
      group.fields.forEach((field) => {
        const dynamicField = this.availableFields.find((x) =>
          x.field_id === field.id
        );
        field.domain = dynamicField?.associated_data_domain_id;
        field.readOnly = dynamicField?.readonly;
        field.isExternal = dynamicField?.isExternal;
        field.associated_data_id = dynamicField?.associated_data_id;
        field.ancestry = dynamicField?.ancestry;
        field.interestAreaRestriction = dynamicField?.accessible_interest_areas;
        field.communityRestriction = dynamicField?.accessible_communities;
      }));
      this.tmpWidgetConfig.filters.list.forEach((filter: DirectoryFieldForFilter) => {
        if (filter.type == 'LinkedChoice') {
          // update filter properties to be more dynamic
          const dynamicField = this.availableFields.find((x) =>
            x.field_id === filter.id
          );
          filter.range = dynamicField?.associated_data_id
          filter.associated_data_id = dynamicField?.associated_data_id
          filter.ancestry = dynamicField?.ancestry
        }
      });
      
  }

  ngOnDestroy(): void {
    this.subscriptionFields.unsubscribe();
  }

  public goBack() {
    this.directoryModificationsRequest.destroyStore();
    this.location.back();
  }

  public askGoBack() {
    if (this.modificationsRequests.length == 0) {
      this.goBack();
    } else {
      const dialogConfirm = this.dialog.open(DirectoryAskLeaveDialogComponent);
      dialogConfirm.afterClosed().subscribe( leave => {
        if (leave) {
          this.directoryEmbedRequestService.newObjectsRequest = {};
          this.goBack();
        }
      });
    }
  }

  public openRequestValidationDialog() {
    this.openRequestValidationDialogWithParam(
      this.modificationsRequests
    ).then(
      (requester: Requester) => {
        if (requester !== undefined && requester !== null) {
          this.directoryModificationsRequest.requester = requester;
          this.directoryModificationsRequest.sendRequest(requester, this.entityId).subscribe(
            res => {
              this.snackbar.open(this.translate.instant("directory.requests.sent"), "OK");
              this.goBack();
            }
          );
        }
      }
    );
  }

  public openRequestValidationDialogWithParam(
    modifications: ModificationsValue[]
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const dialogRef = this.dialog.open(
        DirectoryModificationsRequestValidationComponent,
        {
          width: '80vw',
          maxWidth: '100vw',
          panelClass: 'custom-dialog-field-request-container',
          data: modifications,
        }
      );
      dialogRef.afterClosed().subscribe((requester: Requester) => {
        resolve(requester);
      });
    });
  }
}
