import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectorySearchService } from '../../services/directory-search/directory-search.service';

import { DirectoryConfig, DirectoryField, DirectorySheet } from '../../models/directory-config.model';
import { FieldType } from '../../models/fields/field.model';
import { DirectoryEntityContactDialogComponent } from '../dialog/directory-entity-contact-dialog/directory-entity-contact-dialog.component';
import { CONSTANTS } from 'src/environments/constants';

@Component({
  selector: 'app-directory-entity',
  templateUrl: './directory-entity.component.html',
  styleUrls: ['./directory-entity.component.scss'],
})
export class DirectoryEntityComponent implements OnChanges {
  public FieldType = FieldType;
  public filteredFieldsGroups: any[] = [];
  public currentEntityData: any = "";
  public summary: any = {};
  public currentLang: string;
  public availableFields: any[];
  public mainIsEntity: boolean;
  public currentNotMainTitleId: string;

  public sheetToUse: DirectorySheet;

  public static readonly ENTITY_KEYWORD: string = "entityForm";

  @Input() entityId: string;
  @Input() forceEntity: boolean = false;
  @Input() widgetConfig: DirectoryConfig;
  @Input() range: string;
  @Input() previewMode: boolean;
  @Input() isEditable: boolean;

  constructor(
    private directoryData: DirectorySharedDataService,
    private directorySearch: DirectorySearchService,
    public dialog: MatDialog,
    public translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // init Main or Not main to know if we can use the default name constant
    if (this.widgetConfig) {
      this.mainIsEntity = this.widgetConfig.isFocusOnAssociatedDB && this.isNotEmpty(this.widgetConfig.concernedAssociatedDB) ? false : true;
    }

    // if(changes.entityId && changes.entityId.currentValue !== null && changes.entityId.currentValue !== changes.entityId.previousValue) {
      this.currentLang = this.translateService.currentLang;
      if ((this.range && this.range !== "") && !this.forceEntity) {

        // Getting the ADB's details so we can know whether it's an external one
        // TODO if deemed necessary by customer

        // Getting the element's details
        if (this.range && this.entityId) {
          this.directorySearch
            .getAdbElement(this.range, this.entityId)
            .subscribe((entityData: any) => {
              this.currentEntityData = entityData;
              this.currentNotMainTitleId = null;
              if (this.mainIsEntity) {
                this.currentNotMainTitleId = CONSTANTS.name;
                this.mapFieldValue('title', {id:CONSTANTS.name, code: CONSTANTS.name, type:"Textarea"});
              } else {
                this.mapFieldValue('title', this.widgetConfig.sheet.title);
              }
            });
        }
      } else {
        this.directorySearch
          .findEntityData(this.entityId, true)
          .subscribe((entityData: any) => {
            this.currentEntityData = entityData;
            this.currentNotMainTitleId = null;
            if (!this.mainIsEntity) {
              this.currentNotMainTitleId = CONSTANTS.mainName;
              this.mapFieldValue('title', {id:CONSTANTS.mainName, code: CONSTANTS.mainName, type:"Textarea"});
            } else {
              this.mapFieldValue('title', this.widgetConfig.sheet.title);
            }
          });
      }

      this.filterFieldsThenGroups(this.widgetConfig.sheet.fieldsgroup, this.range);
      this.mapFieldValue('description', this.widgetConfig.sheet.description);
      this.mapFieldValue('logo', this.widgetConfig.sheet.logo);
      this.mapFieldValue('banner', this.widgetConfig.sheet.banner);
    // }
  }

  public isNotEmpty (concernedAssociatedDB: string) {
    return concernedAssociatedDB != undefined && concernedAssociatedDB != null && concernedAssociatedDB != "";
  }

  private mapFieldValue(property: string, field: DirectoryField) {
    let adb = null;
    if (this.range && !this.forceEntity) adb = this.range;

    if (field && field.id) {
      this.directoryData
      .findFieldValue(this.entityId, field.id, adb)
      .subscribe((val) => {
        this.summary[property] = {
          field: field,
          value: val
        };
      });
    } else {
      this.summary[property] = null;
    }
  }

  public filterFieldsThenGroups(fieldsGroups, range: string) {
    //this.showFields = false;
    this.filteredFieldsGroups = [];
    if (range == '') range = null;
    // console.log("FieldsGroup", fieldsGroups, "range", range);

    fieldsGroups.forEach((group) => {
      let newGroup = {order: group.order, title: group.title, fields: []};

      group.fields.forEach((field) => {
        if ((field.domain === range && field.id != 'core_hasListName') //Entities and ADB without 'core_hasListName'
            || (range != null && field.id == 'core_hasListName')) { //Specific to ADB with 'core_hasListName'

          if (this.filteredFieldsGroups.indexOf(newGroup) == -1) this.filteredFieldsGroups.push(newGroup);
          newGroup.fields.push(field);
        }
      });
    });
    // console.debug("Fields AFTER filtering");
    // console.debug(this.filteredFieldsGroups);
    return this.filteredFieldsGroups;
  }

  canContact() {
    if (this.widgetConfig && this.widgetConfig.requests.hasContactRequests
        && this.widgetConfig.requests.contact.code && this.currentEntityData
          && this.currentEntityData[this.widgetConfig.requests.contact.code]) {
      return true;
    }
    return false;
  }

  public openContactDialog(contact?: DirectoryField) {
    let contactCode = this.widgetConfig.requests.contact.code;
    let contactEmail;
    if (this.currentEntityData[contactCode]) {
      contactEmail = this.currentEntityData[contactCode];
    } else {
      contactEmail = this.widgetConfig.responsibleEmail;
    }
    if (contactEmail) {
      const dialogRef = this.dialog.open(DirectoryEntityContactDialogComponent, {
        width: '600px',
        maxWidth: '100vw',
        maxHeight: '90vh',
        data: {
          entity_id: this.entityId,
          entity_name: this.currentEntityData[this.widgetConfig.sheet.title.id],
          widget_name: this.widgetConfig.name,
          referer: document.referrer,
          contact_email: contactEmail
        },
        autoFocus: false,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((success: string) => {});
    }
  }

  public translationCompatibilityForOlderWidget(objectOrString, lang): {} {
    if (typeof objectOrString !== 'object') {
      let tempValue = objectOrString
      objectOrString = {};
      objectOrString[lang] = tempValue;
    }
    return objectOrString[lang];
  }

  // can't use simple quote than encodeURI for backgroundImage url
  public urlEnc(url: string) {
    return encodeURI(url);
  }

  // public getCurrentValue(field) {
  //   if (this.currentEntityData[field.id] instanceof Object) {
  //       return this.currentEntityData[field.id].element_id;
  //   } else {
  //     return this.currentEntityData[field.id];
  //   }
  // }

}
